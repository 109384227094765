import React from 'react';
import styled from "styled-components";
import "../index.css"
import {Link} from "gatsby";
import Footer from "../components/footer";
import VacanciesComponent from "../components/vacanciesComponent";
import News from "../components/news";
import {graphql, useStaticQuery} from "gatsby";
import Header from "../components/header";
import Decoration from "../images/decoration.svg"
import Seo from "../components/seo";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 1005px;
  background: url(${props => props.background});
  margin-top: -62px;
  align-items: center;
  margin-bottom: 200px;
  width: 1920px;
  align-self: center;
  @-moz-document url-prefix() { {
    margin-top: -37px;
  }
  }
  @media screen and (max-width: 1100px) {
    margin-bottom: 170px;
  }
  @media screen and (max-width: 960px) {
    margin-bottom: 150px;
  }
  @media screen and (max-width: 800px) {
    margin-bottom: 130px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1110px;
  align-self: center;
  position: relative;
  @media screen and (max-width: 1100px) {
    width: 1000px;
  }
  @media screen and (max-width: 960px) {
    width: 850px;
  }
  @media screen and (max-width: 800px) {
    width: 700px;
  }
`;

const Title = styled.div`
  font-family: Montserrat;
  font-weight: 800;
  font-size: 36px;
  color: #FFFFFF;
  text-align: left;
  width: 1110px;
  margin-bottom: 43px;
  @media screen and (max-width: 1100px) {
    width: 1000px;
    font-size: 33px;
  }
  @media screen and (max-width: 960px) {
    width: 850px;
    font-size: 30px;
  }
  @media screen and (max-width: 800px) {
    width: 700px;
    font-size: 27px;
  }
`;

const Subtitle = styled.div`
  font-family: Montserrat;
  font-weight: 700;
  font-size: 24px;
  color: #4C77FF;
  margin-bottom: 46px;
  @media screen and (max-width: 1100px) {
    font-size: 20px;
    margin-bottom: 40px;
  }
  @media screen and (max-width: 960px) {
    font-size: 16px;
    margin-bottom: 32px;
  }
  @media screen and (max-width: 800px) {
    font-size: 12px;
    margin-bottom: 27px;
  }
`;

const Text = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  color: #212939;
  line-height: 22px;
  @media screen and (max-width: 1100px) {
    font-size: 13px;
    line-height: 18px;
  }
  @media screen and (max-width: 960px) {
    font-size: 11px;
    line-height: 15px;
  }
  @media screen and (max-width: 800px) {
    font-size: 9px;
    line-height: 12px;
  }
`;

const GazpromText = styled(Text)`
  margin-bottom: 36px;
  @media screen and (max-width: 1100px) {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 960px) {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 800px) {

  }
`;

const TextLink = styled(Link)`
  font-family: Montserrat;
  font-size: 16px;
  color: #444A56;
  line-height: 22px;
  margin-top: 5px;
  @media screen and (max-width: 1100px) {
    font-size: 13px;
    line-height: 18px;
  }
  @media screen and (max-width: 960px) {
    font-size: 11px;
    line-height: 15px;
  }
  @media screen and (max-width: 800px) {
    font-size: 9px;
    line-height: 12px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${props => props.type !== "withoutMargin" ? "197px" : 0};
  @media screen and (max-width: 1100px) {
    margin-bottom: ${props => props.type !== "withoutMargin" ? "170px" : 0};
  }
  @media screen and (max-width: 960px) {
    margin-bottom: ${props => props.type !== "withoutMargin" ? "130px" : 0};
  }
  @media screen and (max-width: 800px) {
    margin-bottom: ${props => props.type !== "withoutMargin" ? "100px" : 0};
  }
`;

const LiText = styled.li`
  font-family: Montserrat;
  font-size: 16px;
  color: #212939;
  line-height: 22px;
  @media screen and (max-width: 1100px) {
    font-size: 14px;
    line-height: 18px;
  }
  @media screen and (max-width: 960px) {
    font-size: 11px;
    line-height: 15px;
  }
  @media screen and (max-width: 800px) {
    font-size: 9px;
    line-height: 12px;
  }
`;

const PartnerCard = styled.div`
  background: rgba(191, 209, 255, 0.29);
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  padding: 18px 0 0 15px;
  box-sizing: border-box;
  width: 255px;
  height: 199px;
  @media screen and (max-width: 1100px) {
    width: 235px;
    height: 170px;
    margin-right: 27px;
  }
  @media screen and (max-width: 960px) {
    width: 215px;
    height: 150px;
    margin-right: 24px;
  }
  @media screen and (max-width: 800px) {
    width: 195px;
    height: 130px;
    margin-right: 20px;
  }
`;

const MarginContainer = styled.div`
  margin-bottom: 180px;
  @media screen and (max-width: 1100px) {
    margin-bottom: 150px;
  }
  @media screen and (max-width: 960px) {
    margin-bottom: 120px;
  }
  @media screen and (max-width: 800px) {
    margin-bottom: 90px;
  }
`;

const ActivitiesImage = styled.img`
  margin-top: -59px;
  @media screen and (max-width: 1100px) {
    width: 400px;
  }
  @media screen and (max-width: 960px) {
    width: 360px;
  }
  @media screen and (max-width: 800px) {
    width: 270px;
  }
`;

const SiburImage = styled.img`
  @media screen and (max-width: 1100px) {
    width: 200px;
  }
  @media screen and (max-width: 960px) {
    width: 180px;
  }
  @media screen and (max-width: 800px) {
    width: 150px;
  }
`;

const RosneftImage = styled.img`
  width: 211px;
  @media screen and (max-width: 1100px) {
    width: 170px;
  }
  @media screen and (max-width: 960px) {
    width: 150px;
  }
  @media screen and (max-width: 800px) {
    width: 120px;
  }
`;

const UniverImage = styled.img`
  width: 165px;
  margin-left: 27px;
  @media screen and (max-width: 1100px) {
    width: 140px;
    margin-left: 23px;
  }
  @media screen and (max-width: 960px) {
    width: 120px;
    margin-left: 18px;
  }
  @media screen and (max-width: 800px) {
    width: 100px;
    margin-left: 12px;
  }
`;

const GazpromImage = styled.img`
  width: 193px;
  margin-left: 25px;
  @media screen and (max-width: 1100px) {
    width: 160px;
    margin-left: 21px;
  }
  @media screen and (max-width: 960px) {
    width: 140px;
    margin-left: 16px;
  }
  @media screen and (max-width: 800px) {
    width: 120px;
    margin-left: 7px;
  }
`;

const OwnUl = styled.ul`
  margin-right: 30px;
`;


const ReturnImages = () => {
    return (
        <>
            <Image src={Decoration} alt="decoration"/>
            <Image2 src={Decoration} alt="decoration"/>
            <Image3 src={Decoration} alt="decoration"/>
            <Image4 src={Decoration} alt="decoration"/>
        </>
    )
}

const Image = styled.img`
  position: absolute;
  left: -450px;
  top: 40px;
`;

const Image2 = styled.img`
  position: absolute;
  left: 800px;
  top: -75px;
  transform: rotate(270deg);
  z-index: -1;
  width: 239px;
`;

const Image3 = styled.img`
  position: absolute;
  left: 1230px;
  top: 720px;
  z-index: -1;
  transform: matrix(-1, 0, 0, 1, 0, 0)
`;

const Image4 = styled.img`
  position: absolute;
  left: -450px;
  top: 1200px;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const MainPage = () => {

    const images = useStaticQuery(query);
    const data = useStaticQuery(query);

    return (
            <div style={{height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                <Seo title={data.allStrapiMainPageSeos.nodes[0].seo_title}
                     description={data.allStrapiMainPageSeos.nodes[0].seo_description}
                     keywords={data.allStrapiMainPageSeos.nodes[0].seo_keywords.split(',')}
                />
                <MainContainer>
                    <HeaderContainer background={images.allStrapiMainPageImages.edges[0].node.image.localFile.url}>
                        <Header type="main"/>
                        <Title>
                            «Пластполимер-Сибирь» -<br/>комплексная разработка и реализация<br/>
                            проектов для предприятий химической,<br/> нефтехимической<br/> и нефтеперерабатывающей<br/>
                            промышленности
                        </Title>
                    </HeaderContainer>
                    <Container>
                        <ReturnImages/>
                        <Subtitle>
                            О компании
                        </Subtitle>
                        <MarginContainer>
                            <Text>
                                ООО «Пластполимер-Сибирь» представляет собой структурно развитое и многофункциональное
                                проектное предприятие. Это стало
                                возможным, благодаря высокому уровню квалификации специалистов,
                                техническому оснащению и применению современных технологий в 3D проектирований,
                                инновационного программного обеспечения, а также ответственному отношению к каждому из
                                наших Заказчиков.
                                <br/><br/>
                                ООО «Пластполимер-Сибирь» зарегистрировано 17 августа 2007 года.
                                Первоначальное название компании Общество с ограниченной ответственностью «ГрадПроект»,
                                о чем сделана запись в единый государственный реестр юридических лиц о создании
                                юридического лица.
                                В декабре 2018 года принято решение об изменении наименования компании на Общество с
                                ограниченной ответственностью
                                «Пластполимер-Сибирь» (ООО «Пластполимер-Сибирь»)
                                <br/><br/>
                                <TextLink to="/about">
                                    Читать больше
                                </TextLink>
                            </Text>
                        </MarginContainer>
                        <Subtitle>
                            Направления деятельности
                        </Subtitle>
                        <RowContainer>
                            <OwnUl>
                                <LiText>
                                    Комплексная разработка и реализация проектов при новом строительстве, реконструкции,
                                    техническом перевооружении предприятий химической, нефтехимической,
                                    и нефтеперерабатывающей
                                    промышленности,
                                    реализации и хранении нефтепродуктов.
                                </LiText>
                                <LiText>
                                    Обустройство нефтяных и газовых месторождений
                                </LiText>
                                <LiText>
                                    Разработка технико-экономического обоснования на выполнения проектных работ.
                                </LiText>
                            </OwnUl>
                            <ActivitiesImage src={images.allStrapiMainPageImages.edges[1].node.image.localFile.url}
                                             alt="activities"/>
                        </RowContainer>
                        <RowContainer type="withoutMargin">
                            <Subtitle>
                                Партнеры
                            </Subtitle>
                            <TextLink to="/partners">
                                Подробнее
                            </TextLink>
                        </RowContainer>
                        <RowContainer>
                            <PartnerCard>
                                <Text style={{marginBottom: "23px"}}>
                                    ПАО «СИБУР»
                                </Text>
                                <SiburImage src={images.allStrapiMainPageImages.edges[2].node.image.localFile.url}
                                            alt="sibur"/>
                            </PartnerCard>
                            <PartnerCard>
                                <Text style={{marginBottom: "9px"}}>
                                    ПАО «НК«Роснефть» - <br/>Алтайнефтепродукт»
                                </Text>
                                <RosneftImage src={images.allStrapiMainPageImages.edges[3].node.image.localFile.url}
                                              alt="sibur"/>
                            </PartnerCard>
                            <PartnerCard>
                                <Text>
                                    Тюменский<br/> государственный<br/> университет
                                </Text>
                                <UniverImage src={images.allStrapiMainPageImages.edges[4].node.image.localFile.url}
                                             alt="sibur"/>
                            </PartnerCard>
                            <PartnerCard style={{marginRight: "0"}}>
                                <GazpromText>
                                    ООО «Газпромнефть-СМ»
                                </GazpromText>
                                <GazpromImage src={images.allStrapiMainPageImages.edges[5].node.image.localFile.url}
                                              alt="sibur"/>
                            </PartnerCard>
                        </RowContainer>
                    </Container>
                    {/* <News type="main"/>*/}
                    <VacanciesComponent type="main"/>
                </MainContainer>
                <Footer/>
            </div>
    )
}

const query = graphql`
query{
  allStrapiMainPageImages(sort: {fields: created_at}) {
    edges {
      node {
        image {
          localFile {
            url
          }
        }
      }
    }
  }
    allStrapiMainPageSeos {
    nodes {
      seo_title
      seo_keywords
      seo_description
    }
  }
}
`;

export default MainPage;